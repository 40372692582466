<template>
  <div class="obijime">
  <ObijimeFilter />
  <Obijime :kimono=$route.params.kimono  :obi=$route.params.obi  obijime="0"/>
  <Obijime :kimono=$route.params.kimono  :obi=$route.params.obi  obijime="1"/>
  <Obijime :kimono=$route.params.kimono  :obi=$route.params.obi  obijime="2"/>
  <Obijime :kimono=$route.params.kimono  :obi=$route.params.obi  obijime="1"/>
  <Obijime :kimono=$route.params.kimono  :obi=$route.params.obi  obijime="2"/>
  <Obijime :kimono=$route.params.kimono  :obi=$route.params.obi  obijime="1"/>
  <Obijime :kimono=$route.params.kimono  :obi=$route.params.obi  obijime="2"/>
  <Obijime :kimono=$route.params.kimono  :obi=$route.params.obi  obijime=""/>
  <Obijime :kimono=$route.params.kimono  :obi=$route.params.obi  obijime="2"/>
  <Obijime :kimono=$route.params.kimono  :obi=$route.params.obi  obijime="1"/>
  <Obijime :kimono=$route.params.kimono  :obi=$route.params.obi  obijime="2"/>
  </div>
</template>


<script>
import Obijime from '../components/Obijime.vue'
import ObijimeFilter from '../components/ObijimeFilter.vue'
export default {
  name: 'App',
  components: {
    Obijime,
    ObijimeFilter
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
