<template>
  <div class="completekimono">
  <h1>Kimono</h1>

  <EditComponent v-bind:kimono="$route.params.kimono"  showbutton=false  v-bind:obi="$route.params.obi"  v-bind:obijime="$route.params.obijime">
  <template v-slot:header>
  ausgewählter Kimono
  </template>
  <table style="width:50%">
   <tr>
     <td><b>Kimono</b></td>
     <td>...</td>
   </tr>
   <tr>
     <td><b>Obi</b></td>
     <td>...</td>
   </tr>
   <tr>
     <td><b>OIbijime</b></td>
     <td>...</td>
   </tr>
  </table>
  <p />
  <router-link v-if="!showbutton" v-bind:to="'/kimono/' + $route.params.obi + '/'  + $route.params.obijime + '/' " class="btn btn-primary btn-lg active" role="button"  >Kimono ändern</router-link>
  <p />
  <router-link v-if="!showbutton" v-bind:to="'/obi/' + $route.params.kimono + '/'  + $route.params.obijime + '/' " class="btn btn-primary btn-lg active" role="button"  >Obi ändern</router-link>
  <p />
  <router-link v-if="!showbutton" v-bind:to="'/obijime/' + $route.params.kimono + '/'  + $route.params.obi + '/' " class="btn btn-primary btn-lg active" role="button"  >Obijime ändern</router-link>

</EditComponent>
  <AnzeigeBig v-bind:kimono="$route.params.kimono"  showbutton=false  v-bind:obi="$route.params.obi"  v-bind:obijime="$route.params.obijime" />
</div>
</template>





<script>
import EditComponent from '../components/EditComponent.vue'
import AnzeigeBig from '../components/AnzeigeBig.vue'


export default {
  name: 'Anzeige',
  components: {
    EditComponent,
    AnzeigeBig
  },
  props: {
    kimono: String,
    obi: String,
    obijime: String,
    showbutton: Boolean
  }
}
</script>
