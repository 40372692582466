<template>
  <EditComponent v-bind:kimono="kimono"   v-bind:obi="obi"  v-bind:obijime="obijime">
  <template v-slot:header>
  Kimono Name
  </template>
  <table style="width:50%">
   <tr>
     <td><b>Typ</b></td>
     <td>Yukata</td>
   </tr>
   <tr>
     <td><b>Farbe</b></td>
     <td>Weiß</td>
   </tr>
   <tr>
     <td><b>Formalität</b></td>
     <td>unformal</td>
   </tr>
  </table>
</EditComponent>
</template>

<script>
import EditComponent from '../components/EditComponent.vue'


export default {
  name: 'Kimono',
  components: {
    EditComponent
  },
  props: {
    kimono: String,
    obi: String,
    obijime: String,
    showbutton: Boolean
  }
}
</script>
