<template>
  <div class="anzeige">
      <b-tabs content-class="mt-3">
        <b-tab title="Vorne" active><img  class="kimonomiximage" v-bind:src="'https://kimonoapp.jonas.huenig.name/kimonos/' + kimono +'.webp'"  alt="Kimono" >  </b-tab>
        <b-tab title="Hinten"> <img  class="kimonomiximage" v-bind:src="'https://kimonoapp.jonas.huenig.name/kimonos/' + kimono +'.webp'"  alt="Kimono" ></b-tab>
    </b-tabs>
     <img class="obimiximage" v-bind:src="'https://kimonoapp.jonas.huenig.name/obis/' + obi +'.webp'"  alt="Obi">
     <img class="obijimemiximage" v-bind:src="'https://kimonoapp.jonas.huenig.name/obijimes/' + obijime +'.webp'"  alt="Obijime">

  </div>
</template>

<script>
export default {
  name: 'Anzeige',
  props: {
    kimono: String,
    obi: String,
    obijime: String
  }
}
</script>

<style scoped>


.kimonomiximage
{
  width:50%;
  height:100%;
}

.obimiximage
{
  position:absolute;
  top:180px;
  left:55px;
  width:90px;
  height:40px;
}

.obijimemiximage
{
  position:absolute;
  top:195px;
  left:55px;
  width:90px;
  height:5px;
}

.kimonomiximage {
  position: relative;
  top: 0;
  left: 0;
  width:189px;
  height:252px;
}

.anzeige {
  position: relative;
  display: inline-block;
  float: left;
  padding: 0px;
  width:200px;
  height:300px;
}
</style>
