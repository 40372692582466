<template>
  <div class="mainclass">
    <div class="text ">
    <h4><slot name="header"></slot></h4>
      <slot></slot>
    <br />
    <router-link v-if="!showbutton" v-bind:to="'/anzeige/' + kimono + '/'  + obi + '/'  + obijime + '/' " class="btn btn-primary btn-lg active" role="button"  >Auswählen</router-link>
    </div>
    <Anzeige v-bind:kimono="kimono"   v-bind:obi="obi"  v-bind:obijime="obijime" />
  </div>
</template>

<script>
import Anzeige from '../components/Anzeige.vue'


export default {
  name: 'EditComponent',
  components: {
    Anzeige
  },
  props: {
    kimono: String,
    obi: String,
    obijime: String,
    showbutton: Boolean
  }
}
</script>

<style scoped>
.text {
float: left;
padding: 5px;
width: 180px;
box-sizing: border-box;
}


.mainclass {
float:left;
margin: 10px;
padding: 10px;
border-style: solid;
}
</style>
