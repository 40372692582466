<template>
  <div class="obi">
  <ObiFilter />
  <Obi :kimono=$route.params.kimono  :obijime=$route.params.obijime  obi="0"/>
  <Obi :kimono=$route.params.kimono  :obijime=$route.params.obijime  obi="1"/>
  <Obi :kimono=$route.params.kimono  :obijime=$route.params.obijime  obi="2"/>
  <Obi :kimono=$route.params.kimono  :obijime=$route.params.obijime  obi="1"/>
  <Obi :kimono=$route.params.kimono  :obijime=$route.params.obijime  obi="2"/>
  <Obi :kimono=$route.params.kimono  :obijime=$route.params.obijime  obi="1"/>
  <Obi :kimono=$route.params.kimono  :obijime=$route.params.obijime  obi="2"/>
  </div>
</template>


<script>
import Obi from '../components/Obi.vue'
import ObiFilter from '../components/ObiFilter.vue'
export default {
  name: 'App',
  components: {
    Obi,
    ObiFilter
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
