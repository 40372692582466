<template>
<div class="anzeigebig">

  <div class="anzeige">
  <h4>Vorne</h4>
  <img  class="kimonomiximage" v-bind:src="'https://kimonoapp.jonas.huenig.name/kimonos/' + kimono +'.webp'"  alt="Kimono" >
  <img class="obimiximage" v-bind:src="'https://kimonoapp.jonas.huenig.name/obis/' + obi +'.webp'"  alt="Obi">
  <img class="obijimemiximage" v-bind:src="'https://kimonoapp.jonas.huenig.name/obijimes/' + obijime +'.webp'"  alt="Obijime">
  </div>
  <div class="anzeige">
  <h4>Hinten</h4>

  <img  class="kimonomiximage" v-bind:src="'https://kimonoapp.jonas.huenig.name/kimonos/' + kimono +'.webp'"  alt="Kimono" >
  <img class="obimiximage" v-bind:src="'https://kimonoapp.jonas.huenig.name/obis/' + obi +'.webp'"  alt="Obi">
  <img class="obijimemiximage" v-bind:src="'https://kimonoapp.jonas.huenig.name/obijimes/' + obijime +'.webp'"  alt="Obijime">
  </div>

  </div>
</template>

<script>
export default {
  name: 'AnzeigeBig',
  props: {
    kimono: String,
    obi: String,
    obijime: String
  }
}
</script>

<style scoped>


.kimonomiximage
{
  width:50%;
  height:100%;
}

.obimiximage
{
  position:absolute;
  top:270px;
  left:100px;
  width:180px;
  height:80px;
}

.obijimemiximage
{
  position:absolute;
  top:310px;
  left:90px;
  width:200px;
  height:5px;
}

.kimonomiximage {
  position: relative;
  top: 0;
  left: 0;
  width:378px;
  height:504px;
}

.anzeige {
  margin-right: 15px;
  position: relative;
  display: inline-block;
  float: left;
  padding: 0px;
  width:400px;
  height:550px;
  #border-style: solid;

}
</style>
